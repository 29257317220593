import { useEffect, useState } from "react";
import { useStore } from "../../stores/store";


interface toggleProps<T> {
    record?: T;
    setRecord?: (rec: T) => void;
    name: string;
    title?: string;
    isRequired?: boolean
    value?: boolean;
    setActiveState?: (x: string) => void
    error?:any
}
const filterProps = {
    IconSlider: "/assets/button/ToggleButton/slider.png",
    IconCross: "/assets/button/ToggleButton/cross.png",
    IconTick: "/assets/button//ToggleButton/tick.png",

}


export const NewToggleSwitch = <T extends {}>({ error, value, isRequired, title, name, record, setRecord, setActiveState }: toggleProps<T>) => {

    const [isclicked, setClick] = useState(value ?? false);




    const onClick = () => {
        setClick(!isclicked)
        if (setRecord && record) setRecord({ ...record, [name]: !isclicked })
        if (setActiveState) {
            isclicked! ? setActiveState("Inactive") : setActiveState("Active")
        }

    }

    useEffect(() => {
        if (value !== undefined) {
            setClick(value);
        }
    }, [value]);



    return (
        <div className="flex flex-col">
            {title && <div className="mb-2">
                <label>
                    <p className={`whitespace-nowrap ${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && "*"}
                        {title}
                    </p>

                </label>
            </div>}


            <div className="w-[200px] h-[30px]  px-1  flex justify-between items-center pt-1 " >
                <div>
                    <p className={`active ${isclicked ? "text-black" : "text-[#D9D9D9]"} `}>Active</p>
                </div>

                {isclicked &&
                    <div className="flex justify-between bg-[#37A041] w-[100px] h-full rounded-lg mr-2 ml-2 ">
                        <div className="w-[50%] flex justify-center items-center   ">
                            <div className="w-6 h-6">
                                <img
                                    className=' '
                                    src={filterProps.IconTick}

                                />
                            </div>

                        </div>

                        <div className="w-[50%] h-full flex justify-center items-center p-[3px] pr-[2px]">
                            <button className="   bg-transparent border-none cursor-pointer  flex justify-end" onClick={onClick}>

                                <img

                                    src={filterProps.IconSlider}
                                />

                            </button>
                        </div>




                    </div>
                }


                {!isclicked &&
                    <div className="flex justify-between bg-[#D9D9D9] w-[100px] h-full rounded-lg mr-2 ml-2">

                        <div className="w-[50%] h-full flex justify-center items-center p-[3px] pl-[2px]">
                            <button className=" bg-transparent border-none cursor-pointer flex justify-end" onClick={onClick}>
                                <img src={filterProps.IconSlider} />
                            </button>
                        </div>

                        <span className="w-[50%] flex justify-center items-center px-3 py-1 ">
                                <img src={filterProps.IconCross} />

                        </span>
                    </div>
                }
                <p className={`active ${!isclicked ? "text-black" : "text-[#D9D9D9]"} font-medium`}>Inactive</p>
            </div>
            {error && <p className="msg-warning">{error}</p>}

        </div>
    )
}
