import { ChangeEvent, useEffect } from "react";

interface Props<T> {
    title?: string;
    name: string;
    isRequired: boolean;
    setRecord: (input: T) => void;
    record: T;
    error?: string;
    value?: string | number | readonly string[] | undefined
    handleClick?: () => void
    width?: string;
    readonly?: boolean;
    clickonly?: boolean;
    id?: string;
    secondRecord?: { longtitude: number, latitude: number, address: string }
    setSecondRecord?: (x: { longtitude: number, latitude: number, address: string }) => void
    secondName?: string;

    handleValidation?: (field: string, value: any) => void
}

export const InputElement = <T extends {}>({ handleValidation, secondName, secondRecord, setSecondRecord, id, clickonly, width, handleClick, value, name, title, isRequired, setRecord, record, error, readonly }: Props<T>) => {

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        if (handleValidation && !setSecondRecord) handleValidation(name, value)
        setRecord({ ...record, [name]: value })
        if (setSecondRecord && secondRecord && secondName) {
        if (handleValidation ) handleValidation(secondName, value)

            console.log(" changing " + name + " inside input")
            setSecondRecord({ ...secondRecord, [secondName]: value })
        }

    }


    const work = () => {
        //console.log("input clicked")
        //console.log(handleClick)
        if (handleClick) {

            handleClick()
        }
    }




    return (
        <div className="flex flex-col" style={{ width: width ? `${width}` : '100%' }}>
            <div className="mb-2">
                <label>
                    <p className={`whitespace-nowrap ${isRequired ? "text-[#CC311B]" : ""}`}>
                        {isRequired && title && "*"}
                        {title}
                    </p>

                </label>
            </div>
            <div>

                <input id={id} style={{ width: width ? `${width}` : '100%' }} readOnly={readonly || clickonly} onClick={work} className={` ${readonly ? "bg-[#D9D9D94D]" : ""} ${error ? "input-warning" : ""}  h-[30px] border-solid border-3 rounded-lg border-[#ACACAC] pl-3`} value={value || ""} name={name} onChange={e => handleChange(e)} />
                {error && <p className="msg-warning">{error}</p>}
            </div>


        </div>
    )

}