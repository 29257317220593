import { GraphData, GraphForm } from "../../models/Pharse2/Graph"
import { useDownloadExcel } from 'react-export-table-to-excel';
import {useEffect, useRef, useState } from "react"

interface Props {
    record: GraphData
    localForm: GraphForm
    stateColor: string[]
    stateGraphColor: string[]
    updateDummy: number
}

export default function TableVisualization({record, localForm, stateColor, stateGraphColor, updateDummy}: Props) {   

    var dataLineCount = 0;
    var alertLineCount = 0;
    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: `Project ${localForm.projectId} ${localForm.fromDate} To ${localForm.toDate}`,
        sheet: `${record.locationName}`
    })
    const tableRendering= () => {
            return  <><div className="relative">
                        <table ref={tableRef}> 
                                <tr>
                                <th></th>
                                {record.xData[0].map((rawDate) => {
                                    var date = rawDate.split("/")
                                    return <th className="border border-black">{date[0] + "/" + date[1]+ "/\n" + date[2]}</th>
                                })}
                                </tr>
                                {record.locationName.map((value, index) => {
                                    return  <tr>
                                                <td className="border border-black">{record.locationName[index]}<div style={{fontWeight: "bold", color: stateGraphColor[dataLineCount++]}}>⎯⎯⎯⎯</div></td>
                                                {record.yData[index].map((data) =>{
                                                    return <td className="border border-black w-auto">{data}</td>
                                                })}
                                            </tr>
                                })}
                                {record.alarmLevels?.map((alarm)=> {
									if(isNaN(alarm.level)) return <></>
                                    return  <tr>
                                                <td className="border border-black">{alarm.name}<div style={{fontWeight: "bold", color: stateColor[alertLineCount++]}}>⎯⎯⎯⎯</div></td>
                                                {record.xData.map((date) =>{
                                                    return <td className="border border-black w-auto">{alarm.value}</td>
                                                })}
                                            </tr>
                                })}
                        </table>
                        <div className="mt-[2%] flex flex-row">Click to export<img src="assets/button/excel.svg" onClick={onDownload}/></div>
                    </div></>
    }
    {
        return tableRendering()
    }
}