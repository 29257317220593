import axios, { AxiosError, AxiosResponse } from 'axios';

import { ResetPasswordModel, User, UserDto, UserFormValue } from '../../models/User';
import { store } from '../../stores/store';
import { router } from '../../router/Routes';
import { toast } from 'react-toastify';
import { Department } from '../../models/Department';
import { SourceType } from '../../models/SourceType';
import { InventoryCounts, Inventory, InventoryDto, InventoryForm, InventoryType, TableHistory } from '../../models/Inventory';
import { Status } from '../../models/Status';
import { Condition } from '../../models/Condition';
import { LoggerSetFormValue } from '../../models/LoggerSet';

import { Project, ProjectDto, ProjectForm, ProjectRequest, ProjectResponse } from '../../models/Project';
import { Company } from '../../models/Company';
import { GraphType } from '../../models/GraphType';
import { MessegeTool } from '../../models/MessegeTool';
import { Alarm, AlarmLevelsMsgToolId, AlarmLevelsUsersId } from '../../models/Alarm';
import { Location, LocationDto, LocationFormValueDto, LocationProject, LocationRequestVal } from '../../models/Location';
import { CustomerDto, CustomerForm } from '../../models/Customer';
import { AccountCreateForm, AccountDto, AccountEditForm, ChangePasswordFormVal } from '../../models/Account';
import { GraphData, GraphForm, SelectListOption } from '../../models/Pharse2/Graph';
import { DataTableDto, IDataForm } from '../../models/Pharse2/Data';
import { IPerformanceForm, IntervalType, PerformTableDto, SummaryType } from '../../models/Pharse2/Performance';
import { LocationMarker, LoggersetDetailsDto, ProjectDistinct } from '../../models/Pharse2/LocationMap';
import { CoustomTableHeaderProps } from '../../components/Table/CoustomTable';
import { AALocationDetailsDto, KeyValue, OverviewDto } from '../../models/Pharse2/Overview';
import { IdName } from '../../models/IdName';
import { OptionContainer } from '../../components/FormElement/MultiLevelSelectElement/MultiLevelSelectElement';


//import GraphForm from '../../pages/Graph/GraphForm';

// axios.defaults.baseURL = 'https://localhost:7277/api';
// axios.defaults.baseURL='https://192.168.2.70/api' ; // UAT server
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const responseBody = <T>(response: AxiosResponse<T>) => { return response.data };


axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`
    }
    
    return config;
})

axios.interceptors.response.use(async response => {

    return response;
}, (error: AxiosError) => {
    if (error.response) {
        const { data, status, config } = error.response as AxiosResponse;
        switch (status) {
            case 400: // ModelStateError or bad guid or just Bad Request 
                if (config.method === 'get' && data.error.hasOwnProperty('id')) {
                    router.navigate("/not-found")
                }
                throw error;
                // if (data.errors) {
                //     const modelStateErrors = []
                //     for (const key in data.errors) {
                //         if (data.errors[key]) { // data.errors[category] is not null => Category is required
                //             modelStateErrors.push(data.errors[key]);
                //         }
                //     }
                //     console.log(modelStateErrors.flat())
                // } else {
                //     throw error
                // }
                break;
            case 401:
                console.log(error)
                router.navigate("/")
                if (localStorage.getItem(`jwt`) !== null) { alert("your login section has been expired, please relogin");  localStorage.removeItem(`jwt`);}
                break;
            case 403:
                toast.error('forbidden')
                break;
            case 404:
                router.navigate("/not-found")
                toast.error('not found') // because not react componentz
                break;
            case 500:
                toast.error('server error')
                //store.commonStore.setServerError(data)
                // router.navigate("/not-found")
                alert("internel server error, please contant Castco IT");
                break;
            default:
                break;

        }
    } else {
        // Handle network error
        console.log(error);
    }
    // return Promise.reject(error)
})



const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: any) => axios.post<T>(url, body).then(responseBody),
    post_2: <T>(url: string, body: any, body2: any) => axios.post<T>(url, { ...body, ...body2 }).then(responseBody),

    put: <T>(url: string, body: any) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    postWithBlobResponse: (url: string, body: any) => axios.post(url, body, { responseType: 'blob' }).then(responseBody),

}

const Account = {
    current: () => requests.get<User>('/Account'),
    list: () => requests.get<UserDto[]>('/Account/UserDtoList'),
    login: (user: UserFormValue) => requests.post<User>('/Account/login', user),
    register: (user: UserFormValue) => requests.post<User>('/account/register', user),
    forget: (user: UserFormValue) => requests.post<void>('/account/forgotpassword', { email: user.email, localUrl: window.location.href }),
    reset: (user: ResetPasswordModel) => requests.post<User>('/account/ResetPassword', user),
    changePw: (form: ChangePasswordFormVal) => requests.post<void>('/account/changepassword', form),
}


const Summary = {
    loadDepartments: () => requests.get<Department[]>('/Summary/department'),
    createDepartment: (department: Department) => requests.post<void>(`/Summary/department`, department),
    loadInvStatus: (selectedId?: string) => requests.get<Status[]>(`/Summary/inventoryStatus?selectedId=${selectedId}`),

    loadLogStatus: (id?: string) => {
        if (id) {
            return requests.get<Status[]>(`/Summary/loggersetStatus?id=${id}`);
        } else {
            return requests.get<Status[]>(`/Summary/loggersetStatus`);
        }
    },

    loadCondi: () => requests.get<Condition[]>('/Summary/condition'),
    loadCompany: () => requests.get<Company[]>('/Summary/Company'),
    loadGraphTypes: () => requests.get<GraphType[]>('/Summary/GraphTypes'),
    loadMessegeTools: () => requests.get<MessegeTool[]>('/Summary/MessegeTools'),

    loadSummarys: () => requests.get<SummaryType[]>('Summary/summarys'),
    loadIntervals: (filterInt?: number[]) => requests.post<IntervalType[]>('Summary/intervals', filterInt)
}

const SourceTypes = {
    list: (isInfo: boolean) => requests.get<SourceType[]>(`/Summary/SourceType?isInfo=${isInfo}`),
    details: (id: string) => requests.get<SourceType>(`/Summary/SourceTypeGet?id=${id}`),
    getHistory: (id: string) => requests.get<TableHistory>(`/Summary/sourceTypeHistory?id=${id}`),
    create: (record: SourceType) => axios.post<void>(`/Summary/SourceType`, record),
    update: (record: SourceType) => axios.put<void>(`/Summary/EditSourceType`, record),
    delete: (ids: string[]) => axios.put<void>(`/Summary/deleteSourceTypes/`, ids),
    GetAllSourceType: (ids: Number[], locId?: Number) => {
        if(locId!=undefined){
            return requests.post<IdName[]>(`/Summary/GetAllSourceType?locId=${locId}`, ids)
        }
        else{
            return requests.post<IdName[]>(`/Summary/GetAllSourceType`, ids)
        }
    }
}
const Inventories = {
        list: () => requests.get<InventoryDto[]>('/inventory/Inventories'),
        listByStatus: (statusId: number) => requests.get<InventoryDto[]>(`/inventory/InventoriesStatus?statusId=${statusId}`),
        details: (id: string) => requests.get<Inventory>(`/inventory/InventoryGet?id=${id}`),
        detailsDto: (id: string) => requests.get<InventoryDto>(`/inventory/InventoryGetDto?id=${id}`),

        delete: (ids: string[]) => axios.put<void>(`/inventory/delete/`, ids),

        create: (inventory: FormData) => requests.post<void>(`/inventory/InventoryCreate`, inventory),
        update: (record: FormData) => axios.put<void>(`/inventory/InventoryEdit`, record),

        getType: () => requests.get<InventoryType[]>('/inventory/Type'),
        getCount: () => requests.get<InventoryCounts>('/inventory/InventoryCount'),
        getHistory: (id: string) => requests.get<TableHistory>(`/Inventory/InventoryHistory?id=${id}`),
        getSourceTypeIdsList: (inventoryId: string) => requests.get<String[]>(`/Inventory/getInventorySourceTypes?inventoryId=${inventoryId}`),

        exportInv: (Ids: Number[]) => requests.postWithBlobResponse(`/Inventory/exportExcel`, Ids),

    }

const Loggersets = {
        list: () => requests.get<LoggerSetFormValue[]>('/Loggerset/GetLoggerSetRecords'),
        details: (id: string) => requests.get<LoggerSetFormValue>(`/Loggerset/getLoggerSet?id=${id}`),
        create: (loggerset: LoggerSetFormValue) => requests.post<void>('/Loggerset/createLoggerSet', loggerset),
        getHistory: (id: string) => requests.get<TableHistory>(`/Loggerset/loggersetHistory?id=${id}`),
        update: (record: LoggerSetFormValue) => axios.put<void>(`/Loggerset/edittingLoggerSet`, record),
        getInfoInventories: (jsonIds: string) => requests.get<InventoryDto[]>(`/Loggerset/InfoInventories?jsonIds=${jsonIds}`),
        getExistLoggerset: (invId: string) => requests.get<LoggerSetFormValue>(`/Loggerset/existLoggerset?id=${invId}`),
        getLoggersetLocation: (logId: string) => requests.get<LocationProject>(`/Loggerset/locationProject?id=${logId}`),
    }

const Projects = {
        create: (record: ProjectForm, alarmLevelsMsgToolId: AlarmLevelsMsgToolId[], alarmLevelsUsersId: AlarmLevelsUsersId[]) => requests.post<void>(`/Project/create`, { ...record, alarmLevelsMsgToolId, alarmLevelsUsersId }),
        update: (record: ProjectRequest) => requests.put<void>(`/Project/Edit`, record),

        list: (selectedId?: string) => {
            if (selectedId) {
                return requests.get<ProjectDto[]>(`/Project/Projects?selectedId=${selectedId}`);
            } else {
                return requests.get<ProjectDto[]>(`/Project/Projects`);
            }
        },

        details: (id: string) => requests.get<ProjectResponse>(`/Project/Project?id=${id}`),
        getHistory: (id: string) => requests.get<TableHistory>(`/Project/History?id=${id}`),
        loadAlarmSrcTypeId: (id: string) => requests.get<string[]>(`/Project/SourceTypeId?id=${id}`),
        loadProjectDtosByIds: (ids: string[]) => requests.post<ProjectDto[]>(`/Project/getDtosByIds`, ids),
    }

const Customers = {
        list: (name: string, companyName: string, telNo: string, mobileNo: string, email: string, page: number) =>
            requests.get<CustomerDto[]>(`/Customer/Customers?name=${encodeURIComponent(name)}&companyName=${encodeURIComponent(companyName)}&telNo=${encodeURIComponent(telNo)}&mobileNo=${encodeURIComponent(mobileNo)}&email=${encodeURIComponent(email)}&page=${page}`),

        details: (id: string) => requests.get<CustomerForm>(`/Customer/Customer?id=${id}`),
        cnt: (name: string, companyName: string, telNo: string, mobileNo: string, email: string) => requests.get<number>(`/Customer/Count?name=${encodeURIComponent(name)}&companyName=${encodeURIComponent(companyName)}&telNo=${encodeURIComponent(telNo)}&mobileNo=${encodeURIComponent(mobileNo)}&email=${encodeURIComponent(email)}`),
    }

const Locations = {
        list: () => requests.get<LocationDto[]>('/Location/GetLocationDTOs'),
        create: (record: LocationRequestVal) => requests.post<void>('/Location/CreateLocation', record),
        details: (locationId: number) => requests.get<LocationRequestVal>(`/Location/GetLocation?locationId=${locationId}`),
        update: (record: LocationRequestVal) => requests.post<void>('/Location/EditLocation', record),
        getHistory: (id: string) => requests.get<TableHistory>(`/Location/History?id=${id}`),
        getLogSets: (selectedId?: string) => {
            if (selectedId) {
                return requests.get<LoggerSetFormValue[]>(`/Location/LogsetRecordsInLoca?selectedId=${selectedId}`)
            } else {
                return requests.get<LoggerSetFormValue[]>(`/Location/LogsetRecordsInLoca`)
            }
        },
    }

const Alarms = {
        details: (id: number, projectId: number) => requests.get<Alarm>(`/Alarm/getAlarm?SourceTypeId=${id}&ProjectId=${projectId}`),
        list: () => requests.get<Alarm[]>('/Alarm/getAllAlarms')
    }

const AccountManagement = {
        details: (id: string) => requests.get<AccountEditForm>(`/UserManagement/editForm?id=${id}`),
        list: () => requests.get<AccountDto[]>('/UserManagement/allDto'),
        update: (record: FormData) => requests.put<void>(`/UserManagement/edit`, record),
        loadingRoles: () => requests.get<string[]>('/UserManagement/allRole'),
        create: (record: AccountCreateForm) => requests.post<void>('/UserManagement/create', record),
        getHistory: (id: string) => requests.get<TableHistory>(`/UserManagement/UserHistory?id=${id}`),
        LoadUserProjectDto: (userId: string) => requests.get<ProjectDto[]>(`/UserManagement/userProjectDto?userId=${userId}`),
    }

const Graph = {
        projectList: () => requests.get<SelectListOption[]>('/Graph/ProjectOptions'),
        locationList: (projectId: number) => requests.get<SelectListOption[]>(`/Graph/LocationtOptions?projectId=${projectId}`),
        sourceTypeList: (locationId: number) => requests.get<OptionContainer[]>(`/Graph/ResourceTypeOptions?locationId=${locationId}`),
        sourceTypeList_InCommon: (locationIds: number[]) => requests.post<OptionContainer[]>(`/Graph/ResourceTypeCommonOptions`, locationIds),


        loggerSetDetails: (locationId: number) => requests.get<{ loggerList: string[], sensorList: string[], otherEquipmentList: string[] }>(`/Graph/LoggersetDetails?locationId=${locationId}`),
        getData2: (form: GraphForm) => requests.post<GraphData>('/Graph/GraphData', form),

    }
const Data = {
        sourceTypeList: (locationIds: number[]) => requests.post<SelectListOption[]>(`/Data/srcOptsWithLocArray`, locationIds),

        getData: (dataForm: IDataForm) => requests.post<DataTableDto>(`/Data/data`, dataForm),
        exportCurrent: (dataForm: IDataForm) => requests.postWithBlobResponse(`/Data/exportCurrent`, dataForm),
        exportAll: (dataForm: IDataForm) => axios.post(`/Data/exportAll`, dataForm, { responseType: 'blob' }),
        deleteData: (data: Array<{}>)=> axios.post(`/Data/deleteData`, data, { responseType: 'blob'})
    }

const Performance = {
        getData: (dataForm: IPerformanceForm) => requests.post<PerformTableDto>(`/Performance/data`, dataForm),

    }
const LocationMap = {
        getProjectData: () => requests.get<ProjectDistinct[]>(`/LocationMap/projectList`),
        getLocationData: () => requests.get<LocationMarker[]>(`/LocationMap/loactionList`),
        getLoggersetDetailsDto: (id: number) => requests.get<any>(`/LocationMap/loggersetDetails?locationId=${id}`),
        //getAALocationDetails:(locationId:number) => requests.get<AALocationDetailsDto>(`/LocationMap/getAALocationDetails?locationId=${locationId}`),

    }

const Overview = {
        getOverviewDtoList: () => requests.get<OverviewDto[]>(`/Overview/getOverviewDtoList`),
        //getOverviewHeader: () => requests.get<{ baseHeader: CoustomTableHeaderProps[], sourceHeader: CoustomTableHeaderProps[] }>(`/Overview/getOverviewHeader`),

        // getHeader: () => requests.get<CoustomTableHeaderProps[]>('/Overview/GetSourcetypeHeader'),
        // getOverview: () => requests.get<OverviewDto[]>('/Overview/getOverview'),
        //getTest : () => requests.get('/Overview/test')

    }

const agent = {
        Summary,
        Account,
        SourceTypes,
        Inventories,
        Loggersets,
        Projects,
        Locations,
        Alarms,
        Customers,
        AccountManagement,
        Graph,
        Data,
        Performance,
        LocationMap,
        Overview
    }

export default agent;