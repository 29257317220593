import DatePicker, { DateObject } from "react-multi-date-picker"
import { GraphData, GraphForm } from "../../models/Pharse2/Graph"
import { useEffect, useState } from "react"
import { number } from "echarts"
import { useStore } from "../../stores/store"

interface Props {
	record?: GraphData
	localForm: GraphForm
	setTable: (x: boolean) => void
	dummyadd: () => void
}

const boxStyle = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 h-[40px] inline focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
export default function TimeIntervalConfig({ record, localForm, setTable, dummyadd }: Props) {
	const [startTime, setStart] = useState([0, 0])
	const [endTime, setEnd] = useState([0, 0])
	const [msgStatus, setMsg] = useState(<text style={{ visibility: "hidden" }} className="mt-[25px]">Please click "Search" button in header to get original record</text>)
	const [dateList, setDateList] = useState<DateObject[] | undefined>(undefined)
	const [presentMode, setMode] = useState(0)
	const { userStore, commonStore } = useStore();
	const { user, CheckIfHkiaEmail } = userStore;
	/*const specialCondition=()=>{                                Any VIP user requirement
	if (user?.username) === 
}
	*/
	useEffect(() => {
		setTable(false)
	}, [record])
	const handleStartHour = (x: number) => {
		if (x >= 0 && x < 24) {
			setStart([x, startTime[1]])
		}
	}
	const handleStartMin = (x: number) => {
		if (x >= 0 && x < 60) {
			setStart([startTime[0], x])
		}
	}
	const handleEndHour = (x: number) => {
		if (x >= 0 && x < 24) {
			if (x === 0) { x = 24 }
			setEnd([x, endTime[1]])
		}
	}
	const handleEndMin = (x: number) => {
		if (x >= 0 && x < 60) {
			setEnd([endTime[0], x])
		}
	}

	const timeFiltering = () => {
		var disableIndex: number[] = []
		var weightedStart = startTime[0] * 60 + startTime[1]
		var weightedEnd = endTime[0] * 60 + endTime[1]
		record?.xData[0].map((xData, xIndex) => {
			var time = xData.split(" ")[1].split(":")
			var AMPM = xData.split(" ")[2] ? xData.split(" ")[2] : "x"
			if (AMPM === "PM") {
				var weightedTime = (parseInt(time[0]) + 12) * 60 + parseInt(time[1])
			}
			else {
				var weightedTime = parseInt(time[0]) * 60 + parseInt(time[1])
			}
			console.log(weightedEnd, weightedStart, weightedTime)
			if (weightedTime >= weightedStart && weightedTime <= weightedEnd) { }
			else { disableIndex.push(xIndex) }
		})
		disableIndex.sort((a, b) => b - a)
		disableIndex.map((value) => {
			record?.xData[0].splice(value, 1)
			record?.yData.map((yDataset) => {
				yDataset.splice(value, 1)
			})
		})
	}

	const dateFiltering = () => {
		var disableIndex: number[] = []
		record?.xData[0].map((xData, xIndex) => {
			var date = xData.split(" ") ? xData.split(" ")[0] : xData
			dateList?.map((disableDate) => {
				if (date === disableDate.toString()) {
					disableIndex.push(xIndex)
				}
			})
		})
		disableIndex.sort((a, b) => b - a)
		disableIndex.map((value) => {
			record?.xData[0].splice(value, 1)
			record?.yData.map((yDataset) => {
				yDataset.splice(value, 1)
			})
		})

	}

	const dateTimeFiltering = () => {
		if (dateList?.length != 0) {
			dateFiltering()
		}
		if ((startTime[0] * 60 + startTime[1]) != 0 && (endTime[0] * 60 + endTime[1]) != 1440) {
			timeFiltering()
		}
	}

	const changeStatus = () => {
		setTable(true)
	}

	const checkDateInterval = () => {
		if (record?.fromDate === null || record?.toDate === null) {
			alert("Please select the date interval and click 'Search' to update data before choosing the present mode!")
		}
	}

	const changeDataPresent = () => {
		if (presentMode === 0) {                                                                 /*Default*/
		}
		else if (presentMode === 1) {                                                             /*Max Per Day*/
			var newXData: string[] = []
			var newYData: number[][] = [[], []]
			record?.xData[0].forEach((xData, xIndex) => {
				var date = xData.split(" ") ? xData.split(" ")[0] : xData
				var newXIndex = newXData.findIndex(x => x === date)
				if (newXIndex === -1) {
					newXData.push(date)
					record.locationIds.forEach((location, locationIndex) => {
						if (newYData[locationIndex] === undefined) { newYData[locationIndex] = [] }
						var yValue = record.yData[locationIndex][xIndex] ? record.yData[locationIndex][xIndex] : NaN
						newYData[locationIndex].push(yValue)
					})
				}
				else {
					record.locationIds.forEach((location, locationIndex) => {
						if ((record.yData[locationIndex][xIndex] > newYData[locationIndex][newXIndex]) || (isNaN(newYData[locationIndex][newXIndex]))) {
							newYData[locationIndex][newXIndex] = record.yData[locationIndex][xIndex]
						}
					})
				}
			})
			record!.locationIds.map((id, locationIndex) => { record!.xData[locationIndex] = newXData })
			record!.yData = newYData
		}
		else if (presentMode === 2) {                                                             /*Max Per Day*/
			var newXData: string[] = []
			var newYData: number[][] = [[], []]
			record?.xData[0].forEach((xData, xIndex) => {
				var date = xData.split(" ") ? xData.split(" ")[0] : xData
				var newXIndex = newXData.findIndex(x => x === date)
				if (newXIndex === -1) {
					newXData.push(date)
					record.locationIds.forEach((location, locationIndex) => {
						if (newYData[locationIndex] === undefined) { newYData[locationIndex] = [] }
						var yValue = record.yData[locationIndex][xIndex] ? record.yData[locationIndex][xIndex] : NaN
						newYData[locationIndex].push(yValue)
					})
				}
				else {
					record.locationIds.forEach((location, locationIndex) => {
						if ((record.yData[locationIndex][xIndex] < newYData[locationIndex][newXIndex]) || (isNaN(newYData[locationIndex][newXIndex]))) {
							newYData[locationIndex][newXIndex] = record.yData[locationIndex][xIndex]
						}
					})
				}
			})
			record!.locationIds.map((id, locationIndex) => { record!.xData[locationIndex] = newXData })
			record!.yData = newYData
		}
		else if (presentMode === 3) {                                                  /*buggy on multi-location*/
			var newXData: string[] = []
			var newYData: number[][] = [[], []]
			var tempYData: number[][][] = [[[]], [[]]]
			record?.xData[0].map((xData, xIndex) => {
				var date = xData.split(" ") ? xData.split(" ")[0] : xData
				if (!(newXData.includes(date))) {
					newXData.push(date)
				}
				var dateIndex = newXData.findIndex(x => x === date)
				record.yData.map((yDataset, locationId) => {
					if (tempYData[locationId] === undefined) { tempYData[locationId] = [] }
					if (tempYData[locationId][dateIndex] === undefined) { tempYData[locationId][dateIndex] = [] }
					if (yDataset[xIndex] != undefined && !Number.isNaN(yDataset[xIndex])) {
						tempYData[locationId][dateIndex].push(yDataset[xIndex])
					}
				})
			})
			tempYData.map((dateset, locationIndex) => {
				dateset.map((yDataset, dateIndex) => {
					var sum = 0
					yDataset.map((yData) => {
						sum = yData + sum
					})
					if (newYData[locationIndex] === undefined) { newYData[locationIndex] = [] }
					newYData[locationIndex][dateIndex] = Math.round(sum / yDataset.filter(x => !isNaN(x)).length * 1000) / 1000
				})
			})
			record!.locationIds.map((id, locationIndex) => { record!.xData[locationIndex] = newXData })
			record!.yData = newYData
		}
		else if (presentMode === 4) {
			var newXData: string[] = [];
			var newYData: number[][] = [[], []];
			
			record?.xData[0].forEach((xData, xIndex) => {
				// Extract hour and date from xData
				var dateTimeParts = xData.split(" ");
				var date = dateTimeParts[0];
				var time = dateTimeParts[1];
				var period = dateTimeParts[2]; // "AM" or "PM"
				
				// Extract hour and convert to 24-hour format
				var timeParts = time.split(":");
				var hour = parseInt(timeParts[0], 10);
				if (period === "PM" && hour < 12) {
					hour += 12; // Convert PM hours
				} else if (period === "AM" && hour === 12) {
					hour = 0; // Convert midnight
				}
				
				// Generate the hour range display with leading zeros
				var displayHourStart = hour.toString().padStart(2, '0');
				var displayHourEnd = (hour + 1) % 24; // End hour for the range
				var displayHourEndString = (displayHourEnd === 0 ? '00' : (displayHourEnd === 12 ? '12' : displayHourEnd.toString().padStart(2, '0')));
				var displayHourString = `${displayHourStart} to ${displayHourEndString}`; // Use leading zeros
			
				var hourKey = `${date} ${displayHourString}`; // Use the hour range for the key
			
				var newXIndex = newXData.findIndex(x => x === hourKey);
				if (newXIndex === -1) {
					newXData.push(hourKey);
					record.locationIds.forEach((location, locationIndex) => {
						if (newYData[locationIndex] === undefined) { newYData[locationIndex] = []; }
						var yValue = record.yData[locationIndex][xIndex] ? record.yData[locationIndex][xIndex] : NaN;
						newYData[locationIndex].push(yValue);
					});
				} else {
					record.locationIds.forEach((location, locationIndex) => {
						var currentYValue = newYData[locationIndex][newXIndex];
						var newYValue = record.yData[locationIndex][xIndex] ? record.yData[locationIndex][xIndex] : NaN;
						if (newYValue > currentYValue || isNaN(currentYValue)) {
							newYData[locationIndex][newXIndex] = newYValue;
						}
					});
				}
			});
			
			// Update the record with the new X and Y data
			record!.locationIds.map((id, locationIndex) => { record!.xData[locationIndex] = newXData; });
			record!.yData = newYData;

		}
		else if (presentMode === 5) {
			var newXData: string[] = [];
var newYData: number[][] = [[], []];

record?.xData[0].forEach((xData, xIndex) => {
    // Extract hour and date from xData
    var dateTimeParts = xData.split(" ");
    var date = dateTimeParts[0];
    var time = dateTimeParts[1];
    var period = dateTimeParts[2]; // "AM" or "PM"
    
    // Extract hour and convert to 24-hour format
    var timeParts = time.split(":");
    var hour = parseInt(timeParts[0], 10);
    if (period === "PM" && hour < 12) {
        hour += 12; // Convert PM hours
    } else if (period === "AM" && hour === 12) {
        hour = 0; // Convert midnight
    }
    
    // Generate the hour range display with leading zeros
    var displayHourStart = hour.toString().padStart(2, '0');
    var displayHourEnd = (hour + 1) % 24; // End hour for the range
    var displayHourEndString = (displayHourEnd === 0 ? '00' : (displayHourEnd === 12 ? '12' : displayHourEnd.toString().padStart(2, '0')));
    var displayHourString = `${displayHourStart} to ${displayHourEndString}`; // Use leading zeros

    var hourKey = `${date} ${displayHourString}`; // Use the hour range for the key

    var newXIndex = newXData.findIndex(x => x === hourKey);
    if (newXIndex === -1) {
        newXData.push(hourKey);
        record.locationIds.forEach((location, locationIndex) => {
            if (newYData[locationIndex] === undefined) { newYData[locationIndex] = []; }
            var yValue = record.yData[locationIndex][xIndex] !== undefined ? record.yData[locationIndex][xIndex] : NaN;
            newYData[locationIndex].push(yValue);
        });
    } else {
        record.locationIds.forEach((location, locationIndex) => {
            var currentYValue = newYData[locationIndex][newXIndex];
            var newYValue = record.yData[locationIndex][xIndex] !== undefined ? record.yData[locationIndex][xIndex] : NaN;
            if (isNaN(currentYValue) || newYValue < currentYValue) {
                newYData[locationIndex][newXIndex] = newYValue;
            }
        });
    }
});

// Update the record with the new X and Y data
record!.locationIds.map((id, locationIndex) => { record!.xData[locationIndex] = newXData; });
record!.yData = newYData;

		}
	}

	const handleClick = () => {
		setMsg(<text style={{ color: "#00FF00" }} className="mt-[25px]">Please click "Search" button in header to get original record</text>)
		dateTimeFiltering()
		changeDataPresent()
		changeStatus()
		dummyadd()
	}
	{
		return <>
			<div style={{ textAlign: "center" }}>
				<div>{msgStatus}</div>
				<DatePicker inputClass="w-[500px]" multiple onChange={setDateList} highlightToday={false} placeholder="Click here to select the disabling dates" format="DD/MM/YYYY" />
				<div className="flex-row w-full">
					<text className="p-2.5">Time Range</text>
					<input defaultValue="00" placeholder="Hours(24)" className={boxStyle} onChange={(e) => handleStartHour(parseInt(e.target.value))}></input>
					<span style={{ fontSize: "25px" }}>:</span>
					<input defaultValue="00" placeholder="Minutes" className={boxStyle} onChange={(e) => handleStartMin(parseInt(e.target.value))}></input>
					<text className="p-2.5">To</text>
					<input defaultValue="00" placeholder="Hours(24)" className={boxStyle} onChange={(e) => handleEndHour(parseInt(e.target.value))}></input>
					<span style={{ fontSize: "25px" }}>:</span>
					<input defaultValue="00" placeholder="Minutes" className={boxStyle} onChange={(e) => handleEndMin(parseInt(e.target.value))}></input>
					<select onClick={checkDateInterval} onChange={(e) => { setMode(parseInt(e.target.value)) }}>
						<option value={0}>All Data</option>
						<option value={1}>Max Per Day</option>
						<option value={2}>Min Per Day</option>
						<option value={3}>Average Per Day</option>
						<option value={4}>Max Per Hour</option>
						<option value={5}>Min Per Hour</option>
					</select>
					<img className={boxStyle} src="assets/button/plain_tick.png" onClick={handleClick} />
				</div>
			</div>
		</>
	}
}