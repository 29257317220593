import { Alarm,CustomInventoryAlarm, UpdatedCustomInventory } from "./Alarm";
import { IdName } from "./IdName";
import { Project } from "./Project";

export interface PostProcessingScript{
    locationId:Number
    resouceTypeId:Number
    script:string
} 
export interface LocationDto{
    id?:number;
    name?: string;
    projectName?: string;
    loggerSetName?: string;
    loggerSN?:string
    address?: string;
    status?:string

}

export interface Location{
    id?:string;
    projectId: number;
    loggerSetId?:number|null;
    name: string;
    dateStart: string;
    dateEnd: string;
    address: string;
    latitude: number;
    longtitude: number;
    status: boolean;
    customInventoryAlarm: CustomInventoryAlarm[]; 
    scripts?:IdName[];
    //alarm: Alarm[];
}

export interface locationForm extends Partial<Location>{}

export class LocationFormValueDto implements locationForm{
    id?: string;
    projectId?: number | undefined=0;
    loggerSetId?: number | undefined | null;
    name?: string | undefined;
    dateStart?: string | undefined;
    dateEnd?: string | undefined;
    address?: string | undefined;
    latitude?: number | undefined;
    longtitude?: number | undefined;
    status?: boolean | undefined;
    customInventoryAlarm: CustomInventoryAlarm[] =[];
    scripts?:IdName[];
    constructor(init?: locationForm) {
        Object.assign(this, init);
      }

}


export interface ILocationError {
    projectId: string;
    loggerSetId:string;
    name: string;
    address: string;
    lat: string;
    lng: string;
    alarm:string;
}

export class LocationErrorValue implements ILocationError{
    name: string="";

    projectId: string="";
    loggerSetId: string="";
    locationCode: string="";
    address: string="";
    lat: string="";
    lng: string="";
    alarm: string="";
    

}

export interface LocationRequest extends Partial<Location>{}

export class LocationRequestVal implements LocationRequest{
    id?: string;
    projectId?: number | undefined=0;
    loggerSetId?: number | undefined | null;
    name?: string | undefined;
    dateStart?: string | undefined;
    dateEnd?: string | undefined;
    address?: string | undefined;
    latitude?: number | undefined;
    longtitude?: number | undefined;
    status?: boolean | undefined;
    customInventoryAlarm?: UpdatedCustomInventory[] | undefined;
}


export interface LocationProject{
    location : Location
    project:Project
}