import { useEffect, useState, FormEvent } from "react";
import { NewToggleSwitch } from "../../FormElement/ToggleButton";
import { ILocationError, LocationErrorValue, LocationFormValueDto, LocationRequestVal } from "../../../models/Location";
import { InputElement } from "../../FormElement/InputElement";
import GeneralButton from "../../Button/GeneralButton";
import { RectangleButton } from "../../Button/RectangleButton"
import { components } from "../../ProjectRecord/ProjectHeader";
import HorizontalDashedLine from "../../FormElement/HorizontalLine";
import LoggerCountInfoDisplay from "../../FormElement/LoggerCountInfoDisplay";
import LoggerSetInfo from "./PopUpInfo/LoggerSetInfo";
import ProjectInfo from "./PopUpInfo/ProjectInfo";
import { useStore } from "../../../stores/store";
import { CustomCalendar } from "../../Input/CustomCalendar";
import { Project } from "../../../models/Project";
import { LoggerSetFormValue } from "../../../models/LoggerSet";
import { Inventory, InventoryDto } from "../../../models/Inventory";
import InventoryAlarmInfo from "./PopUpInfo/InventoryAlarmInfo/InventoryAlarmInfo";
import { Alarm, CustomInventoryAlarm, CustomInventoryAlarmValue, LocationFormAlarmVal } from "../../../models/Alarm";
import { RoundButton } from "../../Button/RoundButton";
import { useNavigate } from "react-router-dom";
import MapComponent from "../../Map/Map";
import { observer } from "mobx-react-lite";
import PhotoElement from "../../PhotoElement/PhotoElement";
import { TextAreaElement } from "../../FormElement/TextAreaElement";
import ScriptInfo from "./PopUpInfo/ScriptInfo";
import * as yup from 'yup';

interface props {
	setRecord: (x: LocationFormValueDto) => void;
	record: LocationFormValueDto;
	inventory: Partial<InventoryDto>;
	project: Project;
	selectedId?: string;
}

interface locationProps {
	selectedId?: string;
}
var count = 0;

export const InventoryComponentSet = ({ setRecord, record, inventory, project, selectedId }: props) => {

	const index = record.customInventoryAlarm.findIndex(
		(item) => item.inventoryId == String(inventory.id)
	);

	var alarmPresent: boolean = record.customInventoryAlarm[index] ? record.customInventoryAlarm[index].alarm.length > 0 : false;
	const [sourceTypeIds, setsourceTypeIds] = useState<String[]>([]);
	const [allAlarms, setallAlarms] = useState<Alarm[]>([]);
	const [custInventoryAlarmRecord, setcustInventoryAlarmRecord] = useState<LocationFormAlarmVal>(alarmPresent ? new CustomInventoryAlarmValue(record.customInventoryAlarm[index]) : new CustomInventoryAlarmValue());
	const [projectAlarms, setprojectAlarms] = useState<Alarm[]>([]);

	const { alarmStore, inventoryStore } = useStore();
	const { getAllAlarms } = alarmStore;
	const { getSourceTypeIds } = inventoryStore;

	console.log(" inside inv comp~~~ " + count); count++;



	useEffect(() => {
		getSourceTypeIds(String(inventory.id)).then(i => { if (i) setsourceTypeIds(i) });
	}, [inventoryStore, inventory]);

	useEffect(() => {
		getAllAlarms().then(i => { if (i) setallAlarms(i) });
	}, [alarmStore])

	useEffect(() => {

		selectedId ? setprojectAlarms(allAlarms.filter(x => x.projectId == project.projectId).filter(y => sourceTypeIds.includes(String(y.sourceTypeId))).filter(x => x.inventoryId == inventory.id))
			: setprojectAlarms(allAlarms.filter(x => x.projectId == project.projectId).filter(y => sourceTypeIds.includes(String(y.sourceTypeId))).filter(x => x.inventoryId == undefined || x.inventoryId == null || x.inventoryId == "0"))

	}, [allAlarms, sourceTypeIds]);

	useEffect(() => {
		// console.log(" project alarms")
		// console.log(projectAlarms)
		if (true) {
			var newCustomInventoryAlarm = [...record.customInventoryAlarm];
			if (newCustomInventoryAlarm[index] && newCustomInventoryAlarm[index].alarm) newCustomInventoryAlarm[index].alarm = projectAlarms;
			setRecord({ ...record, customInventoryAlarm: newCustomInventoryAlarm });
			setcustInventoryAlarmRecord(newCustomInventoryAlarm[index]);
		} else {
			console.log(" alarm present ", alarmPresent)
		}
	}, [projectAlarms]);

	useEffect(() => {
		if (custInventoryAlarmRecord && custInventoryAlarmRecord.inventoryId !== undefined) {

			var newCustomInventoryAlarm = [...record.customInventoryAlarm];
			if (newCustomInventoryAlarm[index]) newCustomInventoryAlarm[index] = custInventoryAlarmRecord as CustomInventoryAlarm;
			setRecord({ ...record, customInventoryAlarm: newCustomInventoryAlarm });
		}
		console.log("custom inventory alarm record inside inventory component ", custInventoryAlarmRecord)
	}, [custInventoryAlarmRecord]);


	const [alarmPopup, setalarmPopup] = useState(false);
	const [scriptPopup, setscriptPopup] = useState(false);

	useEffect(() => {
		console.log(scriptPopup)
	}, [scriptPopup])

	const handleAlarmButton = () => {
		setalarmPopup(true);
	}

	return (
		<div className="relative w-full">
			<ScriptInfo record={record} setRecord={setRecord} locationId={record.id != undefined ? Number(record.id) : undefined} sourceTypeIds={sourceTypeIds} setIsOpen={setscriptPopup} isOpen={scriptPopup} />

			<div className=" absolute  top-[-35px] mt-5 right-0 flex items-center justify-center gap-2 rounded-t-lg  bg-gray-400 text-decoration-line: underline h-auto px-4 py-2 w-auto" >
				<button onClick={handleAlarmButton} className="bg-[#FFF] rounded-full px-2 py-1 h-full" type='button'>
					<span>Source Type Alarm Setting</span>
				</button>
				<button onClick={() => { setscriptPopup(true) }} className="bg-[#FFF] rounded-full px-2 py-1" type='button'>
					<span>Source Type Script Setting</span>
				</button>
			</div>

			<InventoryAlarmInfo DefaultRecord={projectAlarms} alarmlist={(record.customInventoryAlarm[index] && record.customInventoryAlarm[index].alarm) ? record.customInventoryAlarm[index].alarm : []} record={custInventoryAlarmRecord} setRecord={setcustInventoryAlarmRecord} type={''} isOpen={alarmPopup} setisOpen={setalarmPopup} />
			<div className=" h-full w-full border-[8px] border-gray-400 rounded-xl px-[20px] py-[27px] " style={{ borderRadius: '20px' }}>
				<div className="mt-[70px] w-[full] h-[full]  flex flex-row gap-4 items-center justify-center">
					<div className="w-[50%]  flex flex-col gap-4 ">
						<InputElement readonly={true} value={inventory.equipmentName} title={"Logger Name"} name={"equipmentName"} isRequired={false} setRecord={setRecord} record={record} />
						<InputElement readonly={true} value={inventory.serialNo} title={"Serial No."} name={"serialNo"} isRequired={false} setRecord={setRecord} record={record} />
						<InputElement readonly={true} value={inventory.manufacturerName} title={"Manufacturer Name"} name={"manufacturerName"} isRequired={false} setRecord={setRecord} record={record} />
					</div>
					<div className="w-[50%]  flex flex-col gap-4 ">
						<InputElement readonly={true} value={inventory.equipmentCode} title={"Equipment ID"} name={"equipmentCode"} isRequired={false} setRecord={setRecord} record={record} />
						<InputElement readonly={true} value={inventory.modelNo} title={"Model No. "} name={"modelNo"} isRequired={false} setRecord={setRecord} record={record} />
						<InputElement readonly={true} value={inventory.supplierName} title={"Supplier Name"} name={"supplierName"} isRequired={false} setRecord={setRecord} record={record} />
					</div>
					<PhotoElement src={inventory.equipment} showPhoto={inventory.equipment != undefined} />

				</div>
			</div>
		</div>
	)
}


export default observer(function LocationCreateForm({ selectedId }: locationProps) {

	const [record, setRecord] = useState(new LocationFormValueDto());
	//const [record, setRecord] = useState(new LocationRequestVal());
	const [click, handleclick] = useState(false)
	const audienceButton = { title: 'Audiences Table Setting', imgSrc: '/assets/button/audience2.png', Click: () => { } }
	const [project, setproject] = useState<Project | undefined>();
	const [loggerSet, setloggerSet] = useState<LoggerSetFormValue | undefined>();
	const [openMap, setopenMap] = useState(false);
	const [LngLat, setLngLat] = useState({ longtitude: 0, latitude: 0, address: "" });
	//
	const [selectedLoggerSetIds, setSelectedLoggerSetIds] = useState<string[]>([]);
	const [loggerSetInfoisOpen, setloggerSetInfoisOpen] = useState(false);

	const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
	const [projectInfoIsOpen, setprojectInfoisOpen] = useState(false);

	const [error, setErrors] = useState<Partial<ILocationError>>(new LocationErrorValue());

	const { projectStore, loggerSetStore, inventoryStore, locationStore } = useStore();
	const { LoadingProject, selectedProject } = projectStore;
	const { LoadSelectedLoggerset } = loggerSetStore;
	const { getSourceTypeIds } = inventoryStore;
	const { CreateLocation2, LoadSelectedLocation, EditLocation } = locationStore

	const [selectedLocationFormVAL, setselectedLocationFormVAL] = useState<LocationFormValueDto | undefined>(undefined)

	const navigate = useNavigate();


	const validationSchema = yup.object({
		projectId: yup.number().required('Project has to be chosen')
			.test(
				'is-not-zero',
				'Project has to be chosen',  // Error message
				value => value !== 0  // Test function, returns true if the value is not zero
			),
		loggerSetId: yup.number().nullable()
			.test(
				'is-not-zero',
				'Project has to be chosen',  // Error message
				value => value !== 0  // Test function, returns true if the value is not zero
			),
		longtitude: yup.number()
			.typeError('latitude must be a numeric value')
			.required('Number is required'),


		latitude: yup.number()
			.typeError('longtitude must be a numeric value')
			.required('Number is required'),

		address: yup.string().required('Address is required').trim().min(1, "Address can't be empty"),
	});

	const handleValidationChange = (field: string, value: any) => {
		validationSchema.validateAt(field, {
			[field]: value
		})
			.then(() => {
				setErrors(prev => ({ ...prev, [field]: '' }));
			})
			.catch(err => {
				setErrors(prev => ({ ...prev, [field]: err.message }));
			});
	}

	useEffect(() => {
		const fetchData = async (id: string) => {
			await LoadSelectedLocation(id).then(i => {
				setselectedLocationFormVAL(i);
				console.log(i)
				setSelectedProjectIds((pre) => {
					if (i.projectId !== undefined) {
						return [i.projectId.toString()];
					} else {
						return pre;
					}
				})
				// setSelectedLoggerSetIds((pre) => {
				//     if (i.loggerSetId !== undefined) {
				//         return [i.loggerSetId.toString()];
				//     } else {
				//         return pre;
				//     }
				// })
			});
		}
		if (selectedId) fetchData(selectedId);
	}, [selectedId])


	//

	useEffect(() => {
		if (selectedLocationFormVAL) {
			setRecord({ ...selectedLocationFormVAL });

			console.log("check", selectedLocationFormVAL)

			if (selectedLocationFormVAL.latitude && selectedLocationFormVAL.longtitude && selectedLocationFormVAL.address) {

				setLngLat({ latitude: selectedLocationFormVAL.latitude, longtitude: selectedLocationFormVAL.longtitude, address: selectedLocationFormVAL.address })
				setopenMap(true);

			}
		}
		//console.log(selectedLocationFormVAL)
	}, [selectedLocationFormVAL])


	useEffect(() => {
		if (record.projectId && record.projectId != 0) {
			LoadingProject(String(record.projectId)).then(i => {
				if (i) setproject(i)
				console.log("first fetch project is, ", i)

			}
			);
		}
	}, [record.projectId]);



	const handleLocationAssign = (ids: string[]) => {

		var num = parseInt(ids[0])
		var updatedRecord = { ...record, projectId: num, loggerSetId: undefined }

		LoadingProject(String(num)).then(i => {
			if (i) setproject(i)
			console.log("first fetch project is, ", i)

		})
		setRecord(updatedRecord);

	}

	useEffect(() => {
		if (record.loggerSetId) {
			LoadSelectedLoggerset(String(record.loggerSetId)).then(i => { if (i) setloggerSet(i) });
		} else {
			setloggerSet(undefined)
		}


	}, [record.loggerSetId]);

	useEffect(() => {
		//console.log(loggerSet);
		var newCustomAlarm: Array<CustomInventoryAlarm> = [];
		if (loggerSet) {
			if (loggerSet.loggerList.length > 0) {
				for (var logger of loggerSet.loggerList) {

					var log = record.customInventoryAlarm.find(x => x.inventoryId == String(logger.id))
					var customAlarm: CustomInventoryAlarm = {
						inventoryId: String(logger.id),
						alarm: [],

					}
					if (log && log.alarm) {
						customAlarm = {
							inventoryId: String(logger.id),
							alarm: [...log.alarm],

						}
					}
					newCustomAlarm = [...newCustomAlarm, customAlarm]
				}
			}
			if (loggerSet.sensorList.length > 0) {
				for (var logger of loggerSet.sensorList) {

					var log = record.customInventoryAlarm.find(x => x.inventoryId == String(logger.id))
					var customAlarm: CustomInventoryAlarm = {
						inventoryId: String(logger.id),
						alarm: [],

					}
					if (log && log.alarm) {
						customAlarm = {
							inventoryId: String(logger.id),
							alarm: [...log.alarm],

						}
					}
					newCustomAlarm = [...newCustomAlarm, customAlarm]
				}
			}
			if (loggerSet.otherEquipmentList.length > 0) {
				for (var logger of loggerSet.otherEquipmentList) {


					var log = record.customInventoryAlarm.find(x => x.inventoryId == String(logger.id))
					var customAlarm: CustomInventoryAlarm = {
						inventoryId: String(logger.id),
						alarm: [],

					}
					if (log && log.alarm) {
						customAlarm = {
							inventoryId: String(logger.id),
							alarm: [...log.alarm],

						}
					}
					newCustomAlarm = [...newCustomAlarm, customAlarm]
				}
			}

			setRecord({ ...record, customInventoryAlarm: [...newCustomAlarm] })
		}
	}, [loggerSet])

	useEffect(() => {

		console.log("new record", record)
	}, [record]);

	useEffect(() => {
		if (selectedLoggerSetIds.length > 0) {
			var num = parseInt(selectedLoggerSetIds[0]);
			let updatedRecord = { ...record, loggerSetId: num };
			setRecord(updatedRecord);
		}
		if (selectedLoggerSetIds.length == 0) {
			let updatedRecord = { ...record, loggerSetId: undefined };
			setRecord(updatedRecord);
		}
	}, [selectedLoggerSetIds]);

	useEffect(() => {
		handleSearchClick()
	}, [openMap])

	const handleLoggerSetInfoClick = () => {
		if (record.projectId != undefined && record.projectId != 0) {
			setloggerSetInfoisOpen(true)
		} else {
			alert('Please select a project before selecting a logger set.');
		}
	}

	const handleAssignGPSclick = () => {
		setopenMap(!openMap)
	}

	const handleSearchClick = () => {
		handleclick(true)
	}



	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			await validationSchema.validate(record, { abortEarly: false });
			setErrors({})
			console.log("submit form successfully")
		}
		catch (err) {
			if (err instanceof yup.ValidationError) {
				const newErrors: Record<string, string> = {}; // Define the shape of the errors object
				err.inner.forEach((error) => {
					if (error.path) { // Check if path is defined
						newErrors[error.path] = error.message;
					}
				});
				setErrors(newErrors);
				console.log(newErrors)
			}
			return;
		}

		if (selectedId) {
			EditLocation(record).then(i => navigate("/location"))
		} else {
			CreateLocation2(record).then(i => navigate("/location"))
		};
		//create and edit method
	}

	useEffect(() => {
		console.log(" langlat changed");
		console.log(LngLat)
		var temp = { ...record };
		temp = { ...temp, longtitude: LngLat.longtitude }
		temp = { ...temp, latitude: LngLat.latitude }
		temp = { ...temp, address: LngLat.address }
		setRecord(temp)
	}, [LngLat])


	const assignGPSButton = { title: 'Assign GPS Positioning', imgSrc: '/assets/button/assignImg.png', Click: handleAssignGPSclick }
	const onshow = () => {
		console.log(selectedProjectIds)
		console.log(selectedLoggerSetIds)

	}
	return (
		<div className="w-full  flex   h-[full] mt-2">
			<form onSubmit={handleSubmit} autoComplete="off" className="w-full mt-[10px] mb-[10px] flex flex-col gap-4 ">
				<span onClick={onshow} className="text-[20px] font-medium text-decoration-line: underline">General Information</span>
				<NewToggleSwitch value={record.status} record={record} setRecord={setRecord} name="status" isRequired={true} title="Status" />
				<InputElement handleValidation={handleValidationChange} error={error.projectId} readonly={true} handleClick={() => { setprojectInfoisOpen(true) }} record={record} setRecord={setRecord} value={project?.name} title="Project Name" name="projectId" isRequired={true} />
				<ProjectInfo handleLocationAssign={handleLocationAssign} record={record} selectedProjectIds={selectedProjectIds} onSelectedIdsChange={setSelectedProjectIds} isOpen={projectInfoIsOpen} setisOpen={setprojectInfoisOpen} selectedId={selectedId} />
				<InputElement error={error.name} record={record} setRecord={setRecord} value={record.name} title="Location Name" name="name" isRequired={true} />
				<InputElement handleValidation={handleValidationChange} error={error.address} id={"addressInput"} record={record} setRecord={setRecord} value={LngLat.address} title="Address" name="address" setSecondRecord={setLngLat} secondRecord={LngLat} secondName="address" isRequired={true} />
				<div className=" flex gap-4 flex-wrap">
					<div className="w-auto h-[80px]"><InputElement handleValidation={handleValidationChange} error={error.lat} value={LngLat.latitude} record={record} setRecord={setRecord} name="northLatitude" title="Latitude" setSecondRecord={setLngLat} secondRecord={LngLat} secondName="latitude" isRequired={true} /></div>
					<div className="w-auto h-[80px]"><InputElement handleValidation={handleValidationChange} error={error.lng} value={LngLat.longtitude} setSecondRecord={setLngLat} secondRecord={LngLat} secondName="longtitude" record={record} setRecord={setRecord} name="southLatitude" title="Longtitude" isRequired={true} /></div>
					<div style={components} className='w-auto'><GeneralButton title={"Search Coordinates"} imgSrc={'/assets/button/assignImg.png'} handleClick={handleSearchClick} /></div>
					<div style={components} className='w-auto'><GeneralButton title={assignGPSButton.title} imgSrc={assignGPSButton.imgSrc} handleClick={assignGPSButton.Click} /></div>
				</div>
				{openMap &&
					<MapComponent selectedId={selectedId} inputIDstring="addressInput" record={LngLat} setRecord={setLngLat} setSearchClick={handleclick} searchClick={click} />
				}
				<div className="w-[full] flex flex-row gap-4 flex-wrap">
					<div className="w-auto h-[30px]"><CustomCalendar value={record.dateStart?.split('T')[0]} title="Location Period" name="dateStart" isRequired={true} record={record} setRecord={setRecord} /></div>
					<div className="w-auto h-[30px]"><CustomCalendar value={record.dateEnd?.split('T')[0]} name="dateEnd" isRequired={true} record={record} setRecord={setRecord} /></div>
				</div>
				<HorizontalDashedLine />
				{record.loggerSetId === null && <div className="flex flex-row items-center gap-2">
					<LoggerSetInfo selectedProjectIds={selectedLoggerSetIds} record={record} onSelectedIdsChange={setSelectedLoggerSetIds} isOpen={loggerSetInfoisOpen} setisOpen={setloggerSetInfoisOpen} selectedId={selectedId} />
					<RectangleButton backgroundColor="#CC311B" title="Add Loggerset" handleClick={() => { handleLoggerSetInfoClick() }} />
				</div>
				}
				{
					record.loggerSetId !== null && <>
						<span className="text-[20px] font-medium text-decoration-line: underline">Logger Set Information</span>
						<div className="flex flex-row items-center gap-2">

							<InputElement handleValidation={handleValidationChange} error={error.loggerSetId} readonly={true} handleClick={() => { handleLoggerSetInfoClick() }} record={record} setRecord={setRecord} value={loggerSet?.name} title="Logger Set Name" name="loggerSetId" isRequired={true} />
							<RectangleButton backgroundColor="#CC311B" title="Remove Loggerset" handleClick={() => { setRecord({ ...record, loggerSetId: null }) }} />
						</div>
						<div className="w-full flex  items-center justify-between flex-wrap">
							<div className="w-auto h-auto text-wrap"><LoggerCountInfoDisplay loggerset={loggerSet} /></div>
							{/* <GeneralButton title={audienceButton.title} imgSrc={audienceButton.imgSrc} handleClick={audienceButton.Click} /> */}
						</div>
						<span className="text-[20px] font-medium text-decoration-line: underline">Logger Information & Alarm Setting</span>

						{loggerSet != undefined && project &&
							<>
								{loggerSet.loggerList.map((i, k) => (
									<>
										<InventoryComponentSet selectedId={selectedId} key={k} project={project} record={record} setRecord={setRecord} inventory={i} />
									</>
								))}
								{loggerSet.sensorList.map((i, k) => (
									<>
										<InventoryComponentSet selectedId={selectedId} key={k} project={project} record={record} setRecord={setRecord} inventory={i} />
									</>
								))}
								{loggerSet.otherEquipmentList.map((i, k) => (
									<>
										<InventoryComponentSet selectedId={selectedId} key={k} project={project} record={record} setRecord={setRecord} inventory={i} />
									</>
								))}
							</>
						}
					</>}
				<div className="flex gap-16 items-center justify-center m-[40px] flex-wrap">
					<RoundButton type="submit" handleClick={() => { }} title={selectedId ? "Edit" : "Create"} backgroundColor={"#15304B"} border={"#15304B"} textColor={"#FFF"} />
					<RoundButton type={"button"} handleClick={() => { navigate(-1) }} title={"Cancel"} backgroundColor={"#FFF"} border={"#15304B"} textColor={"#15304B"} />
				</div>
			</form>
		</div>
	)
})